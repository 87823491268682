<template>
  <div class="flex_column appList">
    <dog-search
      ref="search"
      :config="searchConfig"
      @search="search"
    ></dog-search>
    <dog-table
      ref="dogTable"
      id="1d23a7e6-7376-49b4-b11e-816242b6e850"
      :columns="tableColumns"
      :service="getList"
      column-type="selection"
      @selection-change="selectedData = $event"
    >
      <permission-button slot="btn" :config="btnConfig"> </permission-button>
    </dog-table>
    <add-application-dialog
      ref="addApplicationDialog"
      @confirm="searchData"
    ></add-application-dialog>
  </div>
</template>

<script>
  import applicationService from '@/api/service/application';
  import addApplicationDialog from './addApplicationDialog.vue';
  import companyService from '@/api/service/company';

  export default {
    name: 'appList',
    components: { addApplicationDialog },
    data() {
      return {
        searchConfig: [
          {
            type: 'select',
            label: '所属公司',
            key: 'companyId',
            attrs: {
              service: companyService.getAllCompany
            }
          }
        ],
        tableColumns: [
          {
            prop: 'operate',
            label: '操作',
            width: 60,
            widthGrow: false,
            render: (h, { props: { row } }) => {
              return (
                <permission-element
                  config={[
                    {
                      code: 'editApplication',
                      callback: () =>
                        this.$refs.addApplicationDialog.openFrame(row.id)
                    }
                  ]}></permission-element>
              );
            }
          },
          { prop: 'appName', label: '应用名', width: 200 },
          { prop: 'appId', label: '应用ID', width: 170, widthGrow: false },
          { prop: 'companyName', label: '所属公司', width: 160 },
          {
            prop: 'appSecret',
            label: '应用密钥',
            width: 280,
            widthGrow: false
          },
          {
            prop: 'appQrCode',
            label: '应用二维码',
            width: 100,
            render: (h, { props: { cur } }) =>
              cur ? (
                <el-image
                  style='height: 30px; margin-right: 10px;'
                  src={cur}
                  fit='contain'
                  preview-src-list={[cur]}
                />
              ) : null
          },
          {
            prop: 'appDesc',
            label: '应用描述',
            width: 200,
            render: (h, { props: { row } }) => (
              <div>
                {row.appDesc &&
                  row.appDesc.split('\n').map((item) => <div>{item}</div>)}
              </div>
            )
          }
        ],
        btnConfig: [
          {
            buttonType: 'primary',
            code: 'addApplication',
            callback: () => this.$refs.addApplicationDialog.openFrame()
          },
          {
            buttonType: 'danger',
            code: 'deleteApplication',
            callback: this.deleteApplication
          }
        ],
        selectedData: []
      };
    },
    mounted() {
      this.searchData();
    },
    methods: {
      searchData() {
        this.$refs.search.search();
      },
      search(query) {
        this.$refs.dogTable.search(query);
      },
      getList(params) {
        return applicationService.getApplicationList(params);
      },
      deleteApplication() {
        if (this.selectedData.length === 0) {
          return this.$message.error('请至少选择一条数据！');
        }
        this.$confirm(`是否确定删除所选数据？`, '提示', {
          type: 'warning'
        }).then(() => {
          applicationService
            .deleteApplications({
              ids: this.selectedData.map((item) => item.id)
            })
            .then(this.searchData);
        });
      }
    }
  };
</script>

<style lang="scss" scoped></style>
